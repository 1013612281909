import React, { useState } from "react";
import styled from "styled-components";
import { Grid, ButtonBase, Modal, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

import { Layout, TopNavigation, FloatingPlayIcon } from "components";
import { VideoDetailNavigation } from "components/TopNavigation";
import VideoPlayer from "components/VideoPlayer";
import coverImage from "./images/TheSentence-poster.jpg";
import publicHearingImage from "./images/MichaelsPublicHearing.jpg";
import michael from "./images/michael.jpg";
import rashawnd from "./images/Rashawnd.jpg";
import court from "./images/court.jpg";
import fileImage from "./images/fileImage.jpg";
import weedImage from "./images/weed.jpg";

const isLive = false;

const seo = {
  title: "Last Prisoner Project - Cannabis Reform Nonprofit | DOCUMENTARY+",
  description: `Through advocacy, policy change, education, release, commutation, expungement and reentry, Last Prisoner Project nonprofit is committed to social justice, criminal justice, prison reform and ending mass incarceration and the unjust war on drugs, legalizing cannabis, and freeing every last cannabis prisoner.`,
};

const ParaLg = styled.p`
  font-size: 1.5em;
  font-weight: 600;
  text-align: left;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    position: absolute;
    max-width: 60vw;
    left: 4rem;
    font-size: 1.75em;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 50vw;
    left: 8rem;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 40vw;
    left: 18rem;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    left: 20rem;
  }
`;

const PageContainer = styled.div`
  background-color: #000;
  color: #fff;
  font-size: 1rem;
`;

const Quote = styled.p`
  font-size: 1.35rem;
  margin-bottom: 1.5rem;
`;

const SectionPara = styled.p`
  text-align: center;
  margin: 0;
`;

const useStyles = makeStyles((theme) => ({
  coverImage: {
    maxHeight: "calc(100vh - 65px)",
    objectFit: "cover",
    objectPosition: "top",
  },
  publicHearingImage: {
    maxHeight: "90vh",
    maxWidth: "50vw",
    objectFit: "contain",
  },
  publicHearingImageContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "end",
    },
  },
  sectionPadding: {
    padding: "1rem",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem 4rem",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "2rem 6rem",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "2rem 8rem",
    },
  },
  singleImage: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "50vw",
      maxHeight: "30vh",
    },
  },
  gridImages: {
    maxHeight: "50vw",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "100%",
    },
  },
  tiltedImageContainer: {
    padding: "2rem 0",
  },
  tiltedImage: {
    [theme.breakpoints.up("md")]: {
      margin: "3rem 0",
      transform: "rotate(-10deg) translate(-55px, -15px)",
    },
  },
  tiltedCaption: {
    textAlign: "left",
    fontSize: "1.5em",
    margin: 0,
    fontWeight: 600,
  },
  fullsizeImg: {
    maxHeight: "80vh",
    objectFit: "cover",
  },
  textLg: {
    fontSize: "1.5em",
    fontWeight: 600,
  },
  buttonLg: {
    backgroundColor: "#c0c6b5",
    marginBottom: "3rem",
    padding: "1rem 1.5rem",
    color: "#000",
    textDecoration: "none",
  },
  paper: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: "80vw",
    [theme.breakpoints.between(0, 600)]: {
      top: `50%`,
      left: `50%`,
      width: "100vw",
    },
  },
  modal: {
    backgroundColor: "black",
  },
  menuIcon: {
    color: theme.typography.color,
  },
  textRight: {
    textAlign: "right",
    padding: "0 4rem",
    cursor: "pointer",
  },
  noDecoration: {
    textDecoration: "none",
  },
  centered: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
  },
  premieringNotif: {
    padding: '1rem',
    position: 'absolute',
    top: '3%',
    left: '2%',
    borderRadius: 4,
    cursor: 'default'
  }
}));

const Sentence = ({ location }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleVideo = () => {
    setOpen(true);
  };

  return (
    <Layout location={location} seo={seo}>
      <TopNavigation />
      <PageContainer>
        <Box style={{ position: "relative", cursor: "pointer" }}>
    
          <FloatingPlayIcon
            float
            onClick={handleVideo}
            height={100}
            width={100}
          /> 
         
          
          <img
            src={coverImage}
            className={`w-full ${classes.coverImage}`}
            alt="The sentence of Michael Thompson"
          />
        </Box>
        <Grid
          container
          alignItems="center"
          className={classes.sectionPadding}
          justifyContent="space-evenly"
        >
          {/* <Grid item md={3} sm={4} hidden /> */}
          <Grid item xs={12} className={classes.centered}>
            <ParaLg>
              “The Sentence of Michael Thompson” <br /> tells the powerful story
              of Michael Thompson, the longest serving non- violent offender in
              Michigan history, and the legal battle surrounding his life
              sentence.
            </ParaLg>
            <div className={classes.publicHearingImageContainer}>
              <img
                src={publicHearingImage}
                alt=""
                className={classes.publicHearingImage}
              />
            </div>
          </Grid>
        </Grid>
        <Container maxWidth="lg">
          <div className={classes.sectionPadding}>
            <SectionPara>
              In 1994, Thompson was sentenced to 42 to 60 years in prison for
              selling three pounds of cannabis to a close friend turned police
              informant, and he remained there even as Michigan legalized the
              recreational use of marijuana. Directed by Kyle Thrash and Haley
              Elizabeth Anderson, The Sentence of Michael Thompson follows
              Michael’s daughter Rashawnda and his lawyer during the fight for
              clemency, and examines both the harmful legacy of the war on drugs
              and the paths forward as national cannabis legalization becomes a
              reality.
            </SectionPara>
          </div>
        </Container>
        <div className={classes.sectionPadding}>
          <img src={michael} alt="Michael" className={classes.singleImage} />
        </div>
        <Container maxWidth="lg">
          <div className={classes.sectionPadding}>
            <SectionPara>
              The Sentence of Michael Thompson is equal parts a condemnation of
              the faults within our criminal justice system and an admiration
              for the Thompson family’s faith and optimism in the face of
              injustice. The film strips away the artifice of thinking that the
              war on drugs was a boon to underprivileged communities, and
              presents it more accurately as a revolving door of mass
              incarceration. In-depth interviews with Thompson’s attorneys and
              family members reveal the heartbreaking damage done to the
              families of the incarcerated and the hypocrisy of a system that
              keeps non-violent offenders locked up as others profit from
              cannabis legalization.
            </SectionPara>
          </div>
        </Container>
        <div className={classes.sectionPadding}>
        <Grid
          container
          className={classes.sectionPadding}
          justifyContent="space-evenly"
        >
          <Grid item sm={6} style={{ padding: '1rem' }}>
            <img
              src={rashawnd}
              alt="Michael"
              className={`${classes.gridImages} w-full`}
            />
          </Grid>
          <Grid item sm={6} style={{ padding: '1rem' }}>
            <img
              src={court}
              alt="Court"
              className={`${classes.gridImages} w-full`}
            />
          </Grid>
        </Grid>
        </div>
        <div className={classes.sectionPadding}>
          <Quote>
            “He has served a quarter of a century for pot. That in itself is a
            crime.” <br />
            —Amy Povah, President of The CAN-DO Foundation.
          </Quote>
        </div>
        <Grid
          container
          className={classes.tiltedImageContainer}
          alignItems="center"
        >
          <Grid item md={6}>
            <img
              src={fileImage}
              alt="Michael file image"
              className={`${classes.tiltedImage} w-full`}
            />
          </Grid>
          <Grid item md={6} className={classes.sectionPadding}>
            <p className={classes.tiltedCaption}>
              This case represents everything that's wrong with our criminal
              justice system. We have a Black American who has been sentenced to
              an absolutely ridiculous sentence, a disproportionate sentence.
              Today, his sentence is […] reserved for the worst of the worst."{" "}
              <br />
              <br />
              —Kim Corral, Michael’s attorney.
            </p>
          </Grid>
        </Grid>
        <img
          src={weedImage}
          alt="marijuana"
          className={`w-full ${classes.fullsizeImg}`}
        />
        <Container maxWidth="lg">
          <div className={classes.sectionPadding}>
            <p className={classes.textLg}>
              There are more arrests for marijuana possession per year than for
              all violent crimes combined.
            </p>
            <p>
              Michael has spent almost half his life in prison for the ‘crime’
              of selling three pounds of weed. In 2018, Michigan voters
              legalized recreational weed. And since then, the recreational
              marijuana business has ballooned to a $1.5 billion dollar annual
              industry in Michigan. Moreover, states spend millions of dollars
              to keep prisoners like Michael incarcerated over a prisoner’s
              lifetime. “Given that recreational and medicinal marijuana is now
              legal in Michigan, allowing Mr. Thompson to continue to serve this
              very draconian sentence is even more offensive and unreasonable.”
              —Michigan Attorney General, Dana Nessel. </p><p> There
              are over 40,000 estimated people imprisoned with cannabis charges
              across the country. For these people, their families and
              communities, the War on Drugs continues. As the U.S. moves to
              decriminalize cannabis, giving rise to major industry, we must
              confront the injustice inflicted upon those who have suffered from
              cannabis convictions. For more information and resources on how to
              help, visit The Last Prisoner Project.
            </p>
          </div>
        </Container>
        <div className={classes.sectionPadding}>
          <a
            href="https://www.lastprisonerproject.org/michael-thompson"
            target="_blank"
            className={classes.noDecoration}
          >
            <ButtonBase className={`${classes.buttonLg} ${classes.textLg}`}>
              The Last Prisoner Project
            </ButtonBase>
          </a>
        </div>
      </PageContainer>
      <Modal
        className={classes.modal}
        disableBackdropClick={true}
        open={open}
        onClose={() => {
          // trackCustomEvent({
          //   category: "video",
          //   action: "Close video",
          //   label: "Exit",
          // });
          setOpen(false);
        }}
        aria-labelledby="the-sentence"
      >
        <div className={classes.paper}>
          <VideoPlayer
            mediaId="2Y0ycHzL"
            url="https://cdn.jwplayer.com/manifests/seXHGBzi.m3u8"
            autostart={true}
            title="The Sentence of Michael Thompson"
            setData={() => {}}
            open={open}
          />
        </div>
      </Modal>
      {(open && (
        <VideoDetailNavigation
          onClose={() => setOpen(false)}
          title="The Sentence of Michael Thompson"
        />
      )) ||
        null}
    </Layout>
  );
};

export default Sentence;
